


































































































































import { Component, Prop, Vue } from 'vue-property-decorator'
@Component
export default class LogoEV extends Vue {
  @Prop({ default: 'var(--blue)' }) color! :string
  @Prop({ default: '#1e1e1c' }) altColor! :string
}
