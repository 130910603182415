import { render, staticRenderFns } from "./CFIconEnvelope.vue?vue&type=template&id=1a283424&"
import script from "./CFIconEnvelope.vue?vue&type=script&lang=ts&"
export * from "./CFIconEnvelope.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports