



import { Component, Prop, Vue } from 'vue-property-decorator'
import LogoBR from './LogoBR.vue'
import LogoER from './LogoER.vue'
import LogoEV from './LogoEV.vue'
import LogoJC from './LogoJC.vue'
import LogoMT from './LogoMT.vue'
import LogoPC from './LogoPC.vue'
import LogoRC from './LogoRC.vue'
import LogoYR from './LogoYR.vue'
import LogoPR from './LogoPR.vue'
import LogoBT from './LogoBT.vue'

@Component({ components: { LogoBR, LogoER, LogoEV, LogoJC, LogoMT, LogoPC, LogoRC, LogoYR, LogoPR, LogoBT } })
export default class CFLogo extends Vue {
    @Prop() site! :string
    @Prop({ required: false, default: undefined }) color! :string
    @Prop({ required: false, default: undefined }) altColor! :string
    get component () {
      return 'Logo' + this.site
    }
}
