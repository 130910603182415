










































import { Component, Vue } from 'vue-property-decorator'
import CFIcon from './CFIcon.vue'

@Component({ components: { 'cf-icon': CFIcon } })
export default class CFIconLetter extends Vue {}
