import Vue, { VueConstructor } from 'vue'
import { VBToggle } from 'bootstrap-vue'
import CFLogo from '../src/components/logos/CFLogo.vue'
import CFIconArrowLeft2 from '../src/components/icons/CFIconArrowLeft2.vue'
import CFIconArrowRight2 from '../src/components/icons/CFIconArrowRight2.vue'
import CFIconCalendar from '../src/components/icons/CFIconCalendar.vue'
import CFIconChat from '../src/components/icons/CFIconChat.vue'
import CFIconEnvelope from '../src/components/icons/CFIconEnvelope.vue'
import CFIconGift from '../src/components/icons/CFIconGift.vue'
import CFIconResponsive from '../src/components/icons/CFIconResponsive.vue'
import CFIconSocialFacebook from '../src/components/icons/CFIconSocialFacebook.vue'
import CFIconSocialGoogle from '../src/components/icons/CFIconSocialGoogle.vue'
import CFIconSocialTwitter from '../src/components/icons/CFIconSocialTwitter.vue'
import CFIconSocialApple from '../src/components/icons/CFIconSocialApple.vue'
import CFIconSocialMicrosoft from '../src/components/icons/CFIconSocialMicrosoft.vue'
import CFIconGestionAbonnement from '../src/components/icons/CFIconGestionAbonnement.vue'
import CFIconFile from '../src/components/icons/CFIconFile.vue'
import CFIconMail from '../src/components/icons/CFIconMail.vue'
import CFIconLetter from '../src/components/icons/CFIconLetter.vue'
import CFIconPhone from '../src/components/icons/CFIconPhone.vue'
import InputPassword from '../src/components/forms/InputPassword.vue'
import InputPasswordSecurityLevel from '../src/components/forms/InputPasswordSecurityLevel.vue'
import DropdownLogos from '../src/components/dropdownLogos/DropdownLogos.vue'
import RubriqueAideConnexion from '../src/components/connexion/RubriqueAideConnexion.vue'
import ContactCard from '../src/components/contact/ContactCard.vue'

const cf: { [componentName: string]: VueConstructor<Vue> } = {
  CFLogo,
  CFIconArrowLeft2,
  CFIconArrowRight2,
  CFIconCalendar,
  CFIconChat,
  CFIconEnvelope,
  CFIconGift,
  CFIconResponsive,
  CFIconGestionAbonnement,
  CFIconFile,
  CFIconMail,
  CFIconLetter,
  CFIconPhone,
  CFIconSocialFacebook,
  CFIconSocialGoogle,
  CFIconSocialTwitter,
  CFIconSocialApple,
  CFIconSocialMicrosoft,
  InputPassword,
  InputPasswordSecurityLevel,
  DropdownLogos,
  RubriqueAideConnexion,
  ContactCard
}

Object.keys(cf).forEach((name) => {
  Vue.component(name.toLowerCase(), cf[name])
})
Vue.directive('b-toggle', VBToggle)

export default cf
