

















import { Component, Vue, Prop } from 'vue-property-decorator'
import CFIconEye from '../icons/CFIconEye.vue'
import CFIconEyeSlash from '../icons/CFIconEyeSlash.vue'

@Component({
  components: { CFIconEye, CFIconEyeSlash },
  inheritAttrs: false
})
export default class InputPassword extends Vue {
  @Prop({ default: '' }) private value!: string;
  @Prop({ default: false }) private isValid!: boolean;
  @Prop({ default: false }) private isInvalid!: boolean;

  type: 'password' | 'text' = 'password';

  toggleDisplay () {
    if (this.type === 'password') {
      this.type = 'text'
    } else {
      this.type = 'password'
    }
  }
}
