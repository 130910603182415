


















import { Vue, Component } from 'vue-property-decorator'
import { BCard, BCardBody, BCardTitle, BCardText, BCardFooter } from 'bootstrap-vue'

@Component({
  components: {
    BCard, BCardBody, BCardTitle, BCardText, BCardFooter
  }
})
export default class ContactCard extends Vue {

}
