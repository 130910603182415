
























import { Component, Vue, Prop } from 'vue-property-decorator'

@Component({
  components: {}
})
export default class InputPasswordSecurityLevel extends Vue {
  @Prop({ default: '' }) private value!: string;
  @Prop({ default: 8 }) private sizeMin!: number;
  @Prop({ default: true }) private withNumber!: boolean;
  @Prop({ default: true }) private withLowercase!: boolean;
  @Prop({ default: true }) private withUppercase!: boolean;
  @Prop({ default: false }) private withSpecial!: boolean;
  @Prop({ default: true }) displaySecurityLevel!: boolean;

  get securityLevel () {
    if (this.value.length === 0) {
      return 0
    }
    if (this.value.length < this.sizeMin) {
      return (33 * this.value.length) / this.sizeMin
    } else {
      let expected = 0
      let actual = 0
      if (this.withNumber) {
        expected++
        if (this.value.match(/\d/)) { actual++ }
      }
      if (this.withLowercase) {
        expected++
        if (this.value.match(/[a-z]/)) { actual++ }
      }
      if (this.withUppercase) {
        expected++
        if (this.value.match(/[A-Z]/)) { actual++ }
      }
      if (this.withSpecial) {
        expected++
        if (this.value.match(/[^a-zA-Z0-9]/)) { actual++ }
      }
      if (actual < expected) {
        // pas les conditions imposés par les règles de sécurité -> moyen
        return 33 + 33 * (actual + 1) / expected
      } else {
        return 100
      }
    }
  }

  get securityColor (): string {
    if (this.securityLevel <= 33) {
      return 'danger'
    } else if (this.securityLevel <= 66) {
      return 'warning'
    } else {
      return 'success'
    }
  }

  get securityLabel (): string {
    if (this.securityLevel <= 33) {
      return 'faible'
    } else if (this.securityLevel <= 66) {
      return 'moyen'
    } else {
      return 'élevé'
    }
  }
}
