































import { Vue, Component, Prop } from 'vue-property-decorator'
import { BDropdown, BDropdownItem } from 'bootstrap-vue'
import CFLogo from '../logos/CFLogo.vue'

@Component({
  components: {
    CFLogo,
    BDropdown,
    BDropdownItem
  }
})
export default class DropdownLogos extends Vue {
@Prop({ default: 'default' }) titre!: string
logos = [
  { code: 'BR', nom: 'leberry' },
  { code: 'ER', nom: 'lechorepublicain' },
  { code: 'EV', nom: 'leveil' },
  { code: 'JC', nom: 'lejdc' },
  { code: 'MT', nom: 'lamontagne' },
  { code: 'PC', nom: 'lepopulaire' },
  { code: 'RC', nom: 'larep' },
  { code: 'YR', nom: 'lyonne' }
]

d: string = 'https://www.'
f: string = '.fr/'
}
