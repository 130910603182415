



















import { Vue, Component } from 'vue-property-decorator'
import { BCollapse, BCard, BCardHeader, BCardBody, BCardText, BButton } from 'bootstrap-vue'

@Component({
  components: {
    BCollapse, BCard, BCardHeader, BCardBody, BCardText, BButton
  }
})
export default class RubriqueAideConnexion extends Vue {
    idCollapse!: string
    created () {
      this.idCollapse = 'v-' + (new Date()).getTime().toString()
    }
}
