



















import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LogoPR extends Vue {
    @Prop({ default: '#007aad' }) color! :string
}
