





























































































import { Component, Prop, Vue } from 'vue-property-decorator'

@Component
export default class LogoBR extends Vue {
    @Prop({ default: 'var(--red)' }) color! :string
}
